import { withRouter } from 'react-router';
import db from '../config/firebase';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as CancelIcon } from '../img/close.svg';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import Title from '../components/common/Title';
import Text from '../components/common/Text';
import RadioInput from '../components/common/RadioInput';
import Icon from '../components/common/Icon';
import { landingRoute } from '../helpers/routes';

const SignUp = () => {
    const YEARLY_PRICE = 'price_1Jig7qFEWEsMSzk33GHKmWrP';
    const MONTHLY_PRICE = 'price_1Jig79FEWEsMSzk3SyrBoKmY';

    const [ price, setPrice ] = useState(YEARLY_PRICE);
    const [ displayName, setDisplayName ] = useState('');

    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const handleSignUp = async event => {
        event.preventDefault();

        setLoading(true);

        const { email, password } = event.target.elements;
        try {
            const { user } = await db
            .auth()
            .createUserWithEmailAndPassword(email.value, password.value);

            if (user) {
                const uid = db.auth().currentUser.uid;
                const userDocRef = db.firestore().collection('users').doc(uid);

                userDocRef
                .set({
                    displayName: displayName
                })

                const checkoutRef = await userDocRef
                        .collection('checkout_sessions')
                        .add({
                            price: price,
                            success_url: `${ window.location.origin }/${ uid }`,
                            cancel_url: `${ window.location.origin }/signup`,
                        });
                        // Wait for the CheckoutSession to get attached by the extension
                        checkoutRef.onSnapshot((snap) => {
                            const { error, url } = snap.data();
                            if (error) {
                                setError(error.message);
                            }
                            if (url) {
                                // We have a Stripe Checkout URL, let's redirect.
                                window.location.assign(url);
                              }
                        });
            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };

    return (
        <>
        <Root>
            <a href={ landingRoute }><CloseIcon icon={ <CancelIcon /> }/></a>

            <StyledTitle bold>
                Subscribe to create menu
            </StyledTitle>

            <form onSubmit={ handleSignUp }>
                <StyledInput autoFocus
                    name="displayName"
                    type="text"
                    placeholder="Business name"
                    primary
                    value={ displayName }
                    onChange={ (e) => setDisplayName(e.target.value) }
                />

                <StyledInput name="email"
                    type="email"
                    placeholder="Email"
                    primary
                />

                <StyledInput name="password"
                    type="password"
                    placeholder="Password"
                    primary
                />

                <Radios>
                    <StyledRadioInput label="290 euros/yearly"
                        value={ YEARLY_PRICE }
                        onChange = { event => setPrice(event.target.value) }
                        checked={ price === YEARLY_PRICE }
                    />

                    <StyledRadioInput label="29 euros/monthly"
                        value={ MONTHLY_PRICE }
                        onChange={ event => setPrice(event.target.value) }
                        checked={ price === MONTHLY_PRICE }
                    />
                </Radios>

                <Error small red>{ error }</Error>

                <Button label={ loading ? "Processing..." : "Subscribe" }
                    type="submit"
                    primary
                    loading={ loading }
                    width="100%"
                />
            </form>

            <StyledText grey>Already subscribed? <StyledLink to="/signin">Login</StyledLink> to continue</StyledText>

            <StyledText gray small>100% secure payment via Stripe</StyledText>
        </Root>
        </>
    );
};

export default withRouter(SignUp);

const CloseIcon  = styled(Icon)`
    position: fixed;
    right: 0;
    top: 0;
`;

const Error = styled(Text)`
    margin-bottom: 12px;
    text-align: center;
`;

const StyledLink = styled(Link)`
    color: ${ ({ theme }) => theme.text };
    text-decoration: underline;
    font-family: bold;
`;

const StyledInput = styled(Input)`
    margin-bottom: 16px;
`;

const StyledRadioInput = styled(RadioInput)`
    margin-bottom: 24px;
`;

const Radios = styled.div`
    margin: 16px 0 32px;
`;

const StyledText = styled(Text)`
    margin-top: 24px;
    text-align: center;
    width: 100%;
`;

const StyledTitle = styled(Title)`
    margin-bottom: 16px;
`;

const Root = styled.div`
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        justify-content: flex-start;
        margin: 80px auto;
        width: 80%;
        padding-bottom: 80px;
    }
`;
